import { gql } from '@apollo/client';

const GET_NATIONAL_INVOICE_PROVIDER = gql`
  query getNationalInvoiceProvider($countryId: String!) {
    getNationalInvoiceProvider(countryId: $countryId) {
      id
      name
    }
  }
`;

export default GET_NATIONAL_INVOICE_PROVIDER;
